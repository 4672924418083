/* src/index.css */

/* Import the Tailwind directives */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */


.user-select-none {
    user-select: none;
}

.user-select-text {
    user-select: text;
}

.price-mobile1 {
    display: none;
    width: 40%;
}


.browse form {
    padding-top: 20px;
    padding-bottom: 20px;
}

.tyu {
    margin-bottom: 16px;
}

.taj {
    text-align: justify;
}

@media screen and (max-width: 341px) {
    .browse {
        margin-left: 20px !important;
    }

    .price-mobile1 {
        margin-left: 30px !important;
        /* margin-top: 20px; */
    }
}


@media screen and (max-width: 434px) {
    .thix {
        flex-direction: column;
        /* justify-content: start; */
        margin-left: -135px;
    }

    .browse {
        margin-left: -30px;
    }

    .filter-section {
        margin-left: 30px !important;
        margin-top: 20px;
    }
}

@media screen and (max-width: 767px) {

    .filter-section {
        margin-left: 65px;
    }

    .shopyy {
        font-size: 22.695px;
    }

    .thix {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .price-mobile1 {
        display: block;
        margin-top: 25px;
    }

    .sliding {
        margin-inline: 10px;
    }

    .btnx {
        margin-left: -8px;
        margin-top: 5px;
    }

    .browse form {
        padding-top: 0;
        padding-bottom: 0;
    }

    .h4 {
        font-size: 16px;
    }

    .tyu {
        margin-top: 0;
    }
}